<template>
  <el-container style="width:100vw; height: 100vh">
    <el-header>
      <Header></Header>
    </el-header>
    <el-row type="flex" justify="center" class="bg">
      <el-col :span="12">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <h2> Informativa sul trattamento dei dati personali </h2>
              <p>
                La informiamo con la presente che il Decreto Legislativo n. 196 del 30 giugno 2003 (“Codice in
                materia di protezione dei dati personali”) prevede la tutela delle persone e di altri soggetti rispetto al
                trattamento dei dati personali.
              </p>
              <p>
                Ai sensi della predetta normativa, il trattamento dei Suoi dati personali sarà improntato ai principi di
                correttezza, liceità e trasparenza e di tutela della Sua riservatezza e dei Suoi diritti.
              </p>
              <p>
                Ai sensi dell’art. 13 del Decreto Legislativo n. 196 del 30 giugno 2003, Le informiamo le seguenti
                informazioni:
              </p>
              <ol>
                <li>
                  <h4> Finalità e modalità del trattamento dei dati </h4>
                  <p>
                    I suoi dati personali, ci sono stati forniti e saranno trattati esclusivamente per finalità strettamente
                    connesse alla gestione contabile ed amministrativa.
                    Il trattamento dei dati personali sarà effettuato tramite supporti cartacei ed informatici dal titolare,
                    dal responsabile/dai responsabili e dagli incaricati.
                  </p>
                </li>
                <li>
                  <h4> Natura della raccolta dei dati e conseguenze di un eventuale mancato conferimento </h4>
                  <p>
                    Il conferimento dei Suoi dati personali è obbligatorio al fine degli adempimenti di legge.
                    Il loro eventuale mancato conferimento non consentirebbe di adempiere agli obblighi contrattuali.
                  </p>
                </li>
                <li>
                  <h4> Comunicazione e diffusione dei dati </h4>
                  <p>
                    I Suoi dati personali ai fini dell’esecuzione del contratto e per le finalità sopra indicate, potranno
                    essere comunicati:
                  </p>
                  <ul>
                    <li>
                      A tutte le persone fisiche e giuridiche (studi di consulenza legale, amministrativa, fiscale, società di
                      revisione, corrieri e spedizionieri, centro elaborazione dati, etc.) nei casi in cui la comunicazione
                      risulti necessaria per le finalità sopra illustrate;
                    </li>
                    <li>
                      Ad istituti bancari per la gestione degli incassi e pagamenti, ed a società di factoring o di recupero
                      crediti;
                    </li>
                    <li>
                      Ai nostri collaboratori e dipendenti appositamente incaricati e nell’ambito delle relative mansioni;
                    </li>
                  </ul>
                  <p>
                    I dati raccolti non sono oggetto di diffusione, al di fuori dei casi di cui sopra.
                  </p>
                  <p>
                    I dati storici delle letture dell’appartamento saranno sempre visibili al titolare dell’ingresso al
                    portale (subentri/cambi di gestione).
                  </p>
                </li>
                <li>
                  <h4> Diritti dell’interessato </h4>
                  <p>
                    In ogni momento potrà esercitare i Suoi diritti nei confronti del titolare del trattamento ai sensi
                    dell’art. 7 del Decreto Legislativo n. 196 del 30 giugno 2003, che per Sua comodità riproduciamo.
                  </p>
                  <ol>
                    <li>
                      L’interessato ha diritto di ottenere la conferma dell’esistenza o meno di dati personali che lo
                      riguardano, anche se non ancora registrati, e la loro comunicazione in forma intelligibile.
                    </li>
                    <li>
                      L’interessato ha diritto di ottenere l’indicazione:
                      <ol type="a">
                        <li> Dell’origine dei dati personali; </li>
                        <li> Delle finalità e modalità del trattamento; </li>
                        <li> Della logica applicata in caso di trattamento effettuato con l’ausilio di strumenti elettronici; </li>
                        <li> Degli estremi identificativi del titolare, dei responsabili e del rappresentante designato ai
                          sensi dell’articolo 5, comma 2 </li>
                        <li> Dei soggetti o delle categorie di soggetti ai quali i dati personali possono essere comunicati
                          o che possono venirne a conoscenza in qualità di rappresentante designato nel territorio
                          dello Stato, di responsabili o incaricati. </li>
                      </ol>
                    </li>
                    <li>
                      L’interessato ha diritto di ottenere:
                      <ol type="a">
                        <li> L’aggiornamento, la rettificazione ovvero, quando vi ha interesse, l’integrazione dei dati; </li>
                        <li> La cancellazione, la trasformazione in forma anonima o il blocco dei dati trattati in
                          violazione di legge, compresi quelli di cui non è necessaria la conservazione in relazione
                          agli scopi per i quali i dati sono stati raccolti o successivamente trattati; </li>
                        <li> L’attestazione che le operazioni di cui alle lettere a) e b) sono state portate a conoscenza,
                          anche per quanto riguarda il loro contenuto, di coloro ai quali i dati sono stati comunicati o
                          diffusi, eccettuato il caso in cui tale adempimento si rivela impossibile o comporta un
                          impiego di mezzi manifestamente sproporzionato rispetto al diritto tutelato.</li>
                        <li></li>
                      </ol>
                    </li>
                    <li>
                      L’interessato ha diritto di opporsi, in tutto o in parte:
                      <ol type="a">
                        <li>Per motivi legittimi al trattamento dei dati personali che lo riguardano, ancorché pertinenti
                          allo scopo della raccolta;</li>
                        <li>Al trattamento di dati personali che lo riguardano a fini di invio di materiale pubblicitario o
                          di vendita diretta o per il compimento di ricerche di mercato o di comunicazione
                          commerciale.</li>
                      </ol>
                    </li>
                  </ol>
                  <p>
                    I diritti di cui all’articolo 4 sono esercitati con richiesta rivolta senza formalità al titolare o al
                    responsabile, anche per il tramite di un incaricato, alla quale è fornito idoneo riscontro senza ritardo.
                  </p>
                  <p>
                    La richiesta rivolta al titolare o al responsabile può essere trasmessa anche mediante lettera
                    raccomandata, telefax o posta elettronica.
                  </p>
                </li>
                <li>
                  <h4> Titolare del trattamento </h4>
                  <p> Il titolare del trattamento è: ESD Servizi Globali s.a.s. di Gioannini Stefano </p>
                </li>
                <li>
                  <h4> Responsabile del trattamento </h4>
                  <p> Il responsabile del trattamento è: Stefano Gioannini </p>
                </li>
              </ol>
            </div>
          </template>
        </el-card>
      </el-col>
    </el-row>
    <div>
      <Footer></Footer>
    </div>

  </el-container>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";

export default {
  name: "PrivacyPolicy",
  components: {Header, Footer},
}
</script>

<style scoped>

  .bg {
    background: #ededed;
    width: 100%;
    align-items: center;
  }

</style>
